exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-tiff-picture-palace-index-js": () => import("./../../../src/pages/events/tiff-picture-palace/index.js" /* webpackChunkName: "component---src-pages-events-tiff-picture-palace-index-js" */),
  "component---src-pages-exhibitions-index-js": () => import("./../../../src/pages/exhibitions/index.js" /* webpackChunkName: "component---src-pages-exhibitions-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-index-js": () => import("./../../../src/pages/interview/index.js" /* webpackChunkName: "component---src-pages-interview-index-js" */),
  "component---src-pages-review-index-js": () => import("./../../../src/pages/review/index.js" /* webpackChunkName: "component---src-pages-review-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

